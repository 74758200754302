// PreviousPathContext.js
"use client";

import { createContext, useContext, useState } from "react";
const PreviousPathContext = createContext();
export const PreviousPathProvider = ({
  children
}) => {
  const [previousPath, setPreviousPath] = useState(null);
  return <PreviousPathContext.Provider value={{
    previousPath,
    setPreviousPath
  }} data-sentry-element="unknown" data-sentry-component="PreviousPathProvider" data-sentry-source-file="PreviousPathContext.jsx">
      {children}
    </PreviousPathContext.Provider>;
};
export const usePreviousPath = () => useContext(PreviousPathContext);